import React, { Component } from "react";
import "./thermometerChart.css";

export default class ThermometerChart extends Component {
    constructor(props) {
        super(props);
        // let max = Math.max.apply(
        //   Math,
        //   this.props.graphData.map(item => {
        //     return item.responseCount;
        //   })
        // );

        // const graphPoints = this.props.graphData.map(item => {
        //     return item.responseCount;
        // })
        // console.log(graphPoints, "<===max");

        this.state = {
            start: 0,
            end: 500, //graphPoints.sort()[graphPoints.length - 1],
            interval: 25,
            value: 10,
            // labels: this.props.graphData.map(gd => {
            //     return {
            //         label: gd.option_label,
            //         position: gd.responseCount
            //     };
            // })

            labels: [ {
                label: "Medicaid",
                position: 100
              },
              {
                label: "Master Brand",
                position: 145
              },
              {
                label: "MarketPlace",
                position: 275
              }]
        };
    }

    UNSAFE_componentWillMount = () => {
        const points = {};
        const { start, interval, end, labels } = this.state;
        const onePixel = (end - start) / 100;
        const offset = start / onePixel;
        let index = 0;
        for (index = start; index <= end; index += interval) {
            points[index / onePixel - offset] = index;
        }

        if(index > end){
          points[100] = end;
        }

        // Add % position for labels
        labels.map((value, index) => {
            const x = (value.position - start) / onePixel;
            labels[index] = { ...value, left: x };
        });

        this.setState({
            points,
            labels
        });
    };

    render() {
        const { start, end, points, interval, value, labels } = this.state;
        const onePixel = (end - start) / 100;
        console.log(this.props.graphData, "<=====thermometerDya");
        return (
            <div className="main-wrraper">
                <div className="cs-slider-wrapper">
                    <div className="cs-slider-outer">
                        <div className="slide-inner-ui">
                            <div className="progressbar" style={{ width: `${value - start}%` }}></div>
                            <div className="line-ui"></div>
                        </div>
                    </div>
                    <div className="value-ui">
                        {labels.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className="text-ui"
                                    style={{
                                        left: `${value.left}%`,
                                        height: `${index % 2 === 0 ? "auto" : "125px"}`
                                    }}
                                >
                                    <p>
                                        {value.label}
                                        <span>{value.position}</span>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div className="thermometer-points">
                        {Object.keys(points).map(index => {
                            return (
                                <div key={index} style={{ left: `${index}%` }}>
                                    <span>{points[index]}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}