import { API_URL } from '../constants';
import {getSessionItem, setSessionItem, setMemoryItem, removeSessionItem, _axios, _dispatch} from '../utilities';
import {ToastsStore} from 'react-toasts';
export {_dispatch};
export const SAVE_AUTH = 'SAVE_AUTH';

export function generateAuthOTPByPhone($this, phoneNumber, areaCode = "+91") {
    return _axios({
      method: 'POST',
      data: {phoneNumber, areaCode},
      url: `${API_URL}/user/account/otp/phone`
    }, $this).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      } else if(err.response.status === 404) {
        return err.response;
      }
    });
}

export function checkUserExists($this, phoneNumber, areaCode = "+91") {
  return _axios({
    method: 'POST',
    data: {phoneNumber, areaCode},
    url: `${API_URL}/user/account/check-user-exists`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
};

export function checkUserExistsByMobileAndSendOtp($this,data) {
  return _axios({
    method: 'POST',
    data: data,
    url: `${API_URL}/user/account/check-user-exists-by-phone/send-otp`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    }
  });
};

export function createSession(response) {
  let auth = getSessionItem('user');
  if(!auth)
    auth = {};
  // Successful login
  const user = response.body.user;
  if(user.isStudentSignUpCompleted === false && user.isTeacherSignUpCompleted === false){
    setMemoryItem('user', {...auth, ...user});
  }else{
    setSessionItem('user', {...auth, ...user});
    setMemoryItem('user', {...auth, ...user});
  }
  
  // Save customer auth token in storage
  return {
    type: SAVE_AUTH,
    payload: true
  };
}
export function loginPhone($this, phoneNumber, password, areaCode = "+91" ) {
  console.log('response loginphone');
  console.log(phoneNumber + " " + password);
  return _axios({
    method: 'POST',
    data: {phoneNumber, password,areaCode},
    url: `${API_URL}/user/account/login/password`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403) {
      return err.response;
    }
  });
  
 }
export function verifyEmailToken($this, verificationToken) {

  return (dispatch) => { 
    return _axios({
      method: 'POST',
      data: {verificationToken},
      url: `${API_URL}/user/account/verify-email`
    }, $this).then((response) => {
      response = response.data;
      // Successful login
      const user = response.body.user;
      setSessionItem('user', user);
      // Save customer auth token in storage
      dispatch({
        type: SAVE_AUTH,
        payload: true
      });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
    });
  };
}

export function createAuth(response) {
  const user = response.body.user;
  setSessionItem('user', user);
  // Save customer auth token in storage
  return {
    type: SAVE_AUTH,
    payload: true
  };
};

export function checkUserExistsByEmail($this, email) {
  return _axios({
    method: 'POST',
    data: {email},
    url: `${API_URL}/user/account/check-user-exists-by-mail`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 400 || err.response.status === 404) {
      return err.response;
    }
  });
}

export function loginViaEmail($this, email, loginType, appleUserIdentifier) {
  const requestData = {
    loginType
  };
  if(email){
    requestData.email = email;
  }
  if(appleUserIdentifier){
    requestData.appleUserIdentifier = appleUserIdentifier;
  }
  return _axios({
    method: 'POST',
    data: requestData,
    url: `${API_URL}/user/account/login/email`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403) {
      return err.response;
    }
  });
 }

 export function loginViaEmailWithPassword($this, credentials) {
  return _axios({
    method: 'POST',
    data: credentials,
    url: `${API_URL}/user/account/login`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 404 || err.response.status === 400) {
      return err.response;
    }
  });
 }

 export function emailVerification($this, token) {
  return _axios({
    method: 'POST',
    data: {token},
    url: `${API_URL}/user/account/verify-email-address`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 404 || err.response.status === 400) {
      return err.response;
    }
  });
 }

 export function sendVerificationMailToUser($this, email) {
  return _axios({
    method: 'POST',
    data: {email},
    url: `${API_URL}/user/account/send-verification-email`
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 404 || err.response.status === 400) {
      return err.response;
    }
  });
 }

 export function sendOTPToEmail($this, email, checkMailExists) {
  const user = getSessionItem('user');
  return _axios({
    method: 'POST',
    data: { email, checkMailExists },
    url: `${API_URL}/user/account/otp/mail`,
    headers: {Authorization: `Bearer ${user.token}`},
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 404 || err.response.status === 400) {
      return err.response;
    }
  });
}

export function verifyOtpUpdateEmail($this, email, otpCode, oldEmail) {
  const user = getSessionItem('user');
  return _axios({
    method: 'POST',
    data: { email, otpCode, oldEmail },
    url: `${API_URL}/user/account/change-email`,
    headers: {Authorization: `Bearer ${user.token}`},
  }, $this).catch((err) => {
    if (err.response.status === 401) {
      window.location = "/logout";
    } else if(err.response.status === 403 || err.response.status === 404 || err.response.status === 400) {
      return err.response;
    }
  });
};
