import React, { Component, Suspense } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { store } from './store';
import {ROUTES} from './routes';
import ContentLoader from './components/ContentLoader';
import RenderRemoteComponent from './components/RenderRemoteComponent';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./css/class.css";

class App extends Component {

  render() {
    return (
        <>
          <BrowserRouter>
            <Suspense fallback={<ContentLoader />}>
              <Switch>
                <Route {...ROUTES.WRAPPER} />
              </Switch>
              <RenderRemoteComponent />
            </Suspense>
          </BrowserRouter>
          
          <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER}/>
        </>
    );
  }
}

export default App;