import axios from 'axios';
import { API_URL } from '../constants';
import {_dispatch, _axios, getSessionItem} from '../utilities';
export {_dispatch};
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REFRESH_CONVERSATIONS = 'REFRESH_CONVERSATIONS';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
export const UPDATE_COUNTER = 'UPDATE_COUNTER';

// export function deleteBankAccount(accountDetailId) {
//   const user = getSessionItem('user');
//   return axios({
//       method: 'DELETE',
//       params: {accountDetailId},
//       headers: {Authorization: `Bearer ${user.token}`},
//       url: `${API_URL}/wallet/account`,
//   });
// }

// export function addBankAccount($this, values) {
//   const user = getSessionItem('user');
//   return _axios({
//       method: 'POST',
//       data: values,
//       headers: {Authorization: `Bearer ${user.token}`},
//       url: `${API_URL}/wallet/account`,
//   }, $this);
// }

export function getConversations(type, skip = 0, title = '', searchFlag = false) {
  const user = getSessionItem('user');
  return (dispatch) => { 
    return axios({
        method: 'GET',
        params: {type, skip, title},
        headers: {Authorization: `Bearer ${user.token}`},
        url: `${API_URL}/chat/conversations`,
    }).then(response => {
        dispatch({
          type: UPDATE_MESSAGES,
          currentTab: type,
          searchFlag: searchFlag,
          append: skip !== 0,
          payload: response.data.body
        });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
      console.log(err);
    });
  };
}

export function refreshConversations(conversations) {
  return {
    type: REFRESH_CONVERSATIONS,
    payload: conversations
  };
}

export function getChatByConversationId(conversationId, nextId) {
  const user = getSessionItem('user');
  const params = { conversationId };
  if (nextId)
    params.nextId = nextId;
  return (dispatch) => {
    return axios({
        method: 'GET',
        params: params,
        headers: {Authorization: `Bearer ${user.token}`},
        url: `${API_URL}/chat/conversation`,
    }).then(response => {
        dispatch({
          type: UPDATE_CHAT,
          conversationId: conversationId,
          chat: response.data.body,
          append: !!nextId,
        });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
    });
  };
}

export function addMessageToConversation(data) {
  return {
    type: ADD_MESSAGE,
    conversationId: data.conversationId,
    payload: data
  };
}

export function updateMessageCounter(){
  return {
    type: UPDATE_COUNTER
  };
}

export function sendMessage(data) {
  const user = getSessionItem('user');
  return (dispatch) => { 
    return axios({
        method: 'POST',
        data,
        headers: {Authorization: `Bearer ${user.token}`},
        url: `${API_URL}/chat/message`,
    }).then(response => {
        dispatch({
          type: ADD_MESSAGE,
          conversationId: data.conversationId,
          payload: response.data.body
        });
    }).catch((err) => {
      if (err.response.status === 401) {
        window.location = "/logout";
      }
    });
  };
}