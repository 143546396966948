import {
  SAVE_DASHBOARD,
  CHANGE_INSTANT_CLASS_STATUS,
  CHANGE_SCHEDULE_CLASS_STATUS,
  _dispatch
} from '../actions/teacher';

import {
  SAVE_CLASS_DRAFT
} from '../actions/utilities';

const INITIAL_STATE = {
    instantClassData: {}, 
    draftClassData: {
        title: "",
        category: {},
        cover: {},
        topics: [],
        level: null,
        description: '',
        agenda: [""],
        preRequisites: [""],
        languages: [],
        lectureDurationInSeconds: 0,
        availabilities: [
          {
            days: [],
            timings: [
              {
                from: null,
                to: null
              }
            ],
            fromDate: null,
            toDate: null
          }
        ],
        timezone: "",
        isFreeClass: true,
        currency: "INR",
        originalPrice: 0,
        discount: 0,
        isFreeTrialAvailable: true
    }, 
    activeInstantClass: {}, 
    dashboardData: [], 
    status: null
};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case SAVE_DASHBOARD:
      return _dispatch({ ...state, ...action.payload }, true, 'save_dashboard');
      break;

    case SAVE_CLASS_DRAFT:
      return _dispatch({ ...state, draftClassData: action.payload }, true, 'save_class_draft');
      break;

    case CHANGE_INSTANT_CLASS_STATUS:
      const instantClasses = state.instantClassData.instantClasses.map(c => (action.payload.activeClassData._id == c._id ? {...c, isActive: action.payload.action == 'activate'} : c) );
      if(action.payload.action == 'activate'){
      	return _dispatch({ ...state, instantClassData: {...state.instantClassData, instantClasses}, activeInstantClass: action.payload.activeClassData }, true, 'save_dashboard');
      }else{
      	return _dispatch({ ...state, instantClassData: {...state.instantClassData, instantClasses}, activeInstantClass: {} }, true, 'save_dashboard');
      }
      break;

    case CHANGE_SCHEDULE_CLASS_STATUS:
      const scheduleClasses = state.instantClassData.instantClasses.map(c => (
        action.payload.activeClassData._id == c._id ?
        {...c, isActive: action.payload.isActive, status: action.payload.status} :
        c
      ));
      return _dispatch({ ...state, instantClassData: {...state.instantClassData, instantClasses: scheduleClasses}}, true, 'save_dashboard');
      break;

    default:
      return state;
  }
}