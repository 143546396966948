import {
  UPDATE_MESSAGES,
  UPDATE_CHAT,
  ADD_MESSAGE,
  NEW_MESSAGE,
  REMOVE_CONVERSATION,
  REFRESH_CONVERSATIONS,
  UPDATE_COUNTER,
  _dispatch
} from '../actions/message';
import {
  UPDATE_MESSAGE_NOTIFICATION_STATUS
} from '../actions/utilities';

const INITIAL_STATE = {ioStatus: {hasPendingMessages: false, hasPendingNotifications: false}, messages: {chatSectionCounter: {}, conversations: [], hasNext: false}, searchFlag: false, new_message: null, currentConversationId: 0, chats: {}, currentTab: 'classes'};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case UPDATE_COUNTER:
      return _dispatch({ ...state, messages:{...state.messages,chatSectionCounter:{...state.messages.chatSectionCounter,personalSectionCount:0}} }, true, 'update_counter');
      break;

    case UPDATE_MESSAGES:
      if (action.append)
        action.payload.conversations = state.messages.conversations.concat(action.payload.conversations);
      return _dispatch({ ...state, searchFlag: action.searchFlag, currentTab: action.currentTab, messages: action.payload }, true, 'messages');
      break;

    case NEW_MESSAGE:
      return _dispatch({ ...state, new_message: action.payload }, true, 'new_message');
      break;

    case REMOVE_CONVERSATION:
      return _dispatch({ ...state, currentTab: action.currentTab, messages: {...state.messages, conversations: state.messages.conversations.filter(c => c.conversionId != action.conversationId)} }, true, 'messages');
      break;

    case REFRESH_CONVERSATIONS:
      return _dispatch({ ...state, messages: {...state.messages, conversations: action.payload} }, true, 'refresh_rooms');
      break;

    case UPDATE_CHAT:
      if (action.append)
        action.chat.chatMessages.push(...state.chats[action.conversationId].chatMessages);

      return _dispatch({ ...state, currentConversationId: action.conversationId, chats: {...state.chats, [action.conversationId]: action.chat} }, true, 'chat');
      break;

     case ADD_MESSAGE:
      if(typeof state.chats[action.conversationId] === 'undefined'){
        return state;
      }
      return _dispatch({ ...state, currentConversationId: action.conversationId, chats: {...state.chats, [action.conversationId]: {...state.chats[action.conversationId], chatMessages: [...state.chats[action.conversationId].chatMessages, action.payload]} } }, true, 'chat');
      break;

    case UPDATE_MESSAGE_NOTIFICATION_STATUS:
      return _dispatch({ ...state, ioStatus: action.payload }, true, 'io_status');
      break;

    default:
      return state;
  }
}