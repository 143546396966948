import {
  MEETING_JOINED,
  _dispatch
} from '../actions/utilities';

const INITIAL_STATE = {currentMeeting: null, status: null}

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case MEETING_JOINED:
      return _dispatch({ ...state, currentMeeting: action.payload }, true, 'current_meeting');
      break;

    default:
      return state;
  }
}